function getCurrentTime() {
	let now = new Date()
	let hours = now.getHours()
	let minutes = now.getMinutes()
	let ampm = hours >= 12 ? 'PM' : 'AM'

	hours = hours % 12
	hours = hours ? hours : 12
	minutes = minutes < 10 ? '0' + minutes : minutes

	let currentTime = hours + ':' + minutes + ' ' + ampm
	return currentTime
}

function updateTime() {
	const timeElement = document.querySelector('.js-time')
	timeElement.textContent = getCurrentTime()
}

updateTime()
setInterval(updateTime, 60000)

function toggleAccordion(button) {
	const content = button.nextElementSibling
	const icon = button.querySelector('svg')
	if (content.classList.contains('hidden')) {
		content.classList.remove('hidden')
		content.classList.add('block')
		icon.style.transform = 'rotate(180deg)'
	} else {
		content.classList.remove('block')
		content.classList.add('hidden')
		icon.style.transform = 'rotate(0deg)'
	}
}
// Функция для открытия любого попапа по классу
function openPopupByClass(className) {
	const popup = document.querySelector(`.${className}`)
	const overlay = document.querySelector('.popup-overlay')
	if (popup && overlay) {
		popup.style.display = 'block'
		overlay.style.display = 'block'
		document.body.classList.add('popup-active')

		// Добавляем обработчик закрытия по клику на оверлей
		overlay.addEventListener('click', function () {
			closePopup(popup)
		})
	}
}

// Функция для закрытия попапа (передается элемент, внутри которого вызывается)
function closePopup(element) {
	const popup = element.closest('.popup')
	const overlay = document.querySelector('.popup-overlay')
	if (popup && overlay) {
		popup.style.display = 'none'
		overlay.style.display = 'none'
		document.body.classList.remove('popup-active')

		// Убираем обработчик закрытия, чтобы избежать накопления событий
		overlay.removeEventListener('click', function () {
			closePopup(popup)
		})
	}
}

// Функция для открытия попапа с формой Sign In
function showSignIn() {
	closePopup(document.querySelector('.js-popup-adult .close-btn'))
	openPopupByClass('js-popup-signin')
}

// Функция для редиректа на Google
function redirectToGoogle() {
	window.location.href = 'https://www.google.com'
}

// Обработчик событий для всех элементов с классом "js-popup-adult-open"
document.querySelectorAll('.js-popup-adult-open').forEach(button => {
	button.addEventListener('click', function () {
		openPopupByClass('js-popup-adult')
	})
})

// Обработчик события для кнопки "Submit"
document
	.querySelector('.js-popup-submit')
	.addEventListener('click', function (event) {
		event.preventDefault() // Предотвращаем отправку формы
		const popupContent = document.querySelector(
			'.js-popup-signin .popup-content'
		)

		// Обновляем содержимое попапа
		popupContent.innerHTML = `
		<h2 class="text-[#414141] text-4xl font-bold">Thank You</h2>
		<p class="text-[#999999] text-base p-6">We have sent you an authorization link by email.</p>
		<button class="btn-green px-14 py-4 text-white w-72 mt-5 js-popup-close-ok">Ok</button>
		`

		// Обработчик события для кнопки "Ok"
		document
			.querySelector('.js-popup-close-ok')
			.addEventListener('click', function () {
				closePopup(this)
			})
	})
// Функция для валидации email через регулярное выражение
function validateEmail(email) {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
	return emailPattern.test(email)
}

// Функция для проверки введенного email и активации/деактивации кнопки Submit
function checkEmailInput() {
	const emailInput = document.querySelector('#email-input')
	const submitButton = document.querySelector('.js-popup-submit')

	emailInput.addEventListener('input', function () {
		if (validateEmail(emailInput.value)) {
			submitButton.disabled = false // Активируем кнопку если email валиден
		} else {
			submitButton.disabled = true // Деактивируем кнопку если email не валиден
		}
	})
}

// Функция для валидации email через регулярное выражение
function validateEmail(email) {
	const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
	return emailPattern.test(email)
}

// Функция для проверки введенного email и активации кнопки "Continue"
function checkEmailInput() {
	const emailInput = document.querySelector('#email-input')
	const submitButton = document.querySelector('.js-popup-submit')
	const emailPlaceholder = document.querySelector('#email-placeholder')

	emailInput.addEventListener('input', function () {
		if (validateEmail(emailInput.value)) {
			// Если email валиден, показываем кнопку и скрываем серый блок
			submitButton.classList.remove('hidden')
			emailPlaceholder.classList.add('hidden')
		} else {
			// Если email не валиден, показываем серый блок и скрываем кнопку
			submitButton.classList.add('hidden')
			emailPlaceholder.classList.remove('hidden')
		}
	})
}

// Вызов функции проверки ввода email при загрузке страницы
checkEmailInput()

const searchIcon = document.getElementById('search-icon')
const searchBox = document.getElementById('search-box')
const okButton = document.getElementById('ok-button')

searchIcon.addEventListener('click', function () {
	if (searchBox.classList.contains('opacity-0')) {
		searchBox.classList.remove('opacity-0', 'pointer-events-none')
		searchBox.classList.add('opacity-100')
	} else {
		searchBox.classList.remove('opacity-100')
		searchBox.classList.add('opacity-0', 'pointer-events-none')
	}
})

document.addEventListener('click', function (event) {
	if (!searchIcon.contains(event.target) && !searchBox.contains(event.target)) {
		searchBox.classList.remove('opacity-100')
		searchBox.classList.add('opacity-0', 'pointer-events-none')
	}
})

okButton.addEventListener('click', function () {
	searchBox.classList.remove('opacity-100')
	searchBox.classList.add('opacity-0', 'pointer-events-none')
})
